.btn.apply-dp {
  background-color: #7367f0 !important;
}

.btn.close-dp {
  background-color: #28c76f !important;
}
.react-datepicker {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  font-family: 'Montserrat' !important;
}
.react-datepicker__input-container input {
  font-family: serif !important;
}

.react-datepicker__header {
  text-align: center;
  border-bottom: 1px solid #f9f9f9;
  padding: 8px 0;
}
.react-datepicker-year-header,
.react-datepicker__header * {
  font-size: 1.1rem !important;
}
.react-datepicker__day-names {
  margin-top: 5px;
  background: white;
  font-weight: bold;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__month--selected,
.react-datepicker__quarter--selected {
  color: #673ab7 !important;
  font-size: 1.1rem !important;
  font-weight: bold;
}
.react-datepicker-year-header {
  border-bottom: 1px solid #ddd !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #6f6f6f;
}

.react-datepicker__day--disabled {
  color: #bebebe;
}

.react-datepicker__month {
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-top: #ddd;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #f0f0f0;
}
.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  //
  background-color: #7367f07d !important;
  // background-color: #7367f07d;
  color: #454;
  // border: 1px solid #7367f07d;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #7367f07d !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  border-radius: 50%;
  background-color: #7367f07d !important;
}

.react-datepicker__year .react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 50%;
  font-size: 1.3rem !important;
  font-weight: 600;
}
.react-datepicker__year .react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 105px;
  font-size: 1rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  text-align: center;
  margin: 0.166rem;
  font-size: 1.15rem;
  padding: 8px 5px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 2.7rem;
  height: 2.7rem;
  line-height: 1.7rem;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  top: 10px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  height: 12px;
  width: 12px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 4px 4px 0px 0;
}

div.react-datepicker__header__dropdown react-datepicker__header__dropdown--select {
  margin-top: 10px;
  margin-bottom: 10px;
}

select.react-datepicker__year-select,
select.react-datepicker__month-select {
  margin-top: 5px;
  padding: 6px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}

.react-datepicker__year-wrapper > div.react-datepicker__year-text {
  width: 48%;
  font-size: 1.2rem;
}
.react-datepicker__tab-loop{
  position: relative;
}

.week-picker-calendar {
  .react-datepicker__week-number{
    font-size: 14px;
    font-weight: bold;
    color: black;
    padding: 2px;
  }
  .react-datepicker__week-number--selected {
    color: #ffffff;
  }
  .react-datepicker__week:hover{
    background-color: #e1e1e180;
  }
  .react-datepicker__day:hover{
    background-color: transparent;
  }
  .react-datepicker__day--selected{
    background-color: transparent;
    color: #6f6f6f !important;
    border: none;
    font-weight: normal;
    font-size: 1.15rem;
    :hover{
      background-color: transparent;
    }
  }
}
.react-datepicker-wrapper{
  width: 100%;
}
.semi-annual-picker-calendar {
  .react-datepicker__quarter-2 , .react-datepicker__quarter-4{
     display: none !important;
     visibility: hidden !important;
     height: 0 !important;
     width: 0  !important;
  }
}