/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .table-responsive {
    scrollbar-width: 8px;
    scrollbar-color: #a7a8b4b7 #ffffff;
    cursor: pointer;
    min-height: 300px;
  }

  /* Chrome, Edge, and Safari */
  .table-responsive::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  .table-responsive::-webkit-scrollbar-track {
    background: #ffffff;
    cursor: pointer;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #a7a8b4b7;
    border-radius: 10px;
    border: 1px solid #ffffff;
    cursor: pointer;
  }